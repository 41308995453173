import React from "react"
import styles from "./privacy-policy.module.scss"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import SEO from "../../components/seo"
import Header from "../../components/buckmemo/header"
import PageFrame from "../../components/page-frame"
import Footer from "../../components/footer"

export default ({ location }) => {
	const intl = useIntl()
	return (<>
		<SEO
			title={intl.formatMessage({ id: "buckmemo.terms_of_use" })}
			pathname={location.pathname}
			siteName="buckmemo.name" />
		<Header aboutLinked={false} aboutLabel="terms_of_use.title" />
		<PageFrame>
			<FormattedMessage id="terms_of_use.content"
				values={{
					page_title: (...chunks) => <div className={styles.pageTitle}>{chunks}</div>,
					last_updated: (...chunks) => <div className={styles.lastUpdated}>{chunks}</div>,
					desc: (...chunks) => <div className={styles.desc}>{chunks}</div>,
					subheader: (...chunks) => <div className={styles.subHeader}>{chunks}</div>,
					ul: (...chunks) => <ul>{chunks}</ul>,
					li: (...chunks) => <li>{chunks}</li>,
					a: (...chunks) => <a href="mailto:support@walker-one.com">{chunks}</a>
				}} />
		</PageFrame>
		<Footer showAbout={true} showPrivacyPolicy={true} part="buckmemo" />
	</>)
}